// Translated
// Migrated
<template>
  <NuxtLayout name="banner-default">
    <div class="startpage-rolfs pb-12">
      <AlgoliaSearchBar
        class="px-0 -mt-10"
        :autofocus="false"
        size="lg"
      />
      <div class="flex flex-wrap">
        <div class="w-12/12">
          <ReviewScroller :reviews="reviews" />
        </div>
        <div
          v-if="showCustomCampaign"
          class="w-12/12"
        >
          <LazyRolfsStartpageCustomCampaign />
        </div>
        <HydrateWhenVisible class="min-h-[980px] md:min-h-[950px]">
          <LazyHomePitch />
        </HydrateWhenVisible>
        <HydrateWhenVisible class="size-full min-h-[1200px] md:min-h-[400px]">
          <LazyHomeTripList />
        </HydrateWhenVisible>
        <HydrateWhenVisible class="min-h-[130px] md:min-h-[80px] w-full">
          <LazyHomeCategories />
        </HydrateWhenVisible>
        <template v-if="campaignList.length > 0">
          <div class="w-12/12 mb-6 pb-2 text-left md:text-center">
            <h3
              class="mb-4 startpage-ongoing-campaigns"
              data-i18n="activeCampaigns"
            >
              {{ $t('activeCampaigns') }}
            </h3>
            <p data-i18n="activeCampaignsText">
              {{ $t('activeCampaignsText') }}
            </p>
          </div>
          <div class="w-12/12">
            <HydrateWhenVisible class="min-h-[350px] md:min-h-[450px]">
              <LazyHomeCampaigns :campaigns="campaigns" />
            </HydrateWhenVisible>
          </div>
        </template>
        <div
          v-if="hasBlog"
          class="w-12/12 mb-6"
        >
          <HydrateWhenVisible class="min-h-[650px] md:min-h-[600px]">
            <LazyHomeBlogList />
          </HydrateWhenVisible>
        </div>
        <div class="w-12/12 mb-6">
          <HydrateWhenVisible class="min-h-[900px] md:min-h-[800px]">
            <LazyHomeCalendar />
          </HydrateWhenVisible>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup>
import { LOCALES_WITH_STARTPAGE_CUSTOM_CAMPAIGN, LOCALES_WITH_BLOG } from '@layers/web/constants/locale.js'

const rootStore = useRootStore()
const localeStore = useLocaleStore()
const blogStore = useBlogStore()

const tripStore = useTripStore()

const { locale } = storeToRefs(localeStore)
const campaignsStore = useCampaignsStore()

const reviewsStore = useReviewsStore()
const { reviews } = storeToRefs(reviewsStore)

const { t } = useI18n()
const { urls: localeURLs } = useLocale()

const showCustomCampaign = computed(() => {
  return LOCALES_WITH_STARTPAGE_CUSTOM_CAMPAIGN.includes(locale.value)
})

const { data } = await useAsyncData(async () => {
  localeStore.SET_PAGE_WIDE_TRANSLATIONS(createPageWideTranslation(['homeDescription', 'siteTitle', 'siteTitleBrand']))

  const [categoryStartTrips, campaigns] = await Promise.all([
    tripStore.fetchCategoryStart(),
    campaignsStore.fetchPromoted(),
    blogStore.fetchBlogList(),
    reviewsStore.fetchAll(),
    rootStore.fetchStartCalendar(),
    rootStore.fetchStartCategories(),
  ])

  const firstTrip = categoryStartTrips?.[0]
  if (!firstTrip) {
    return
  }

  const pageBanner = {
    title: firstTrip.title,
    subtitle: firstTrip.subtitle,
    special: {
      type: 'crown',
    },

    link: {
      text: t('readMore'),
      href: firstTrip.url,
    },
  }

  // const isVideo = firstTrip.main_movie
  const isVideo = false
  if (isVideo) {
    pageBanner.background = {
      video: {
        src: firstTrip.main_movie,
        type: firstTrip.main_movie_type,
        poster: firstTrip.main_picture_cloudinary,
      },
    }
  } else {
    pageBanner.background = {
      image: {
        url: firstTrip?.main_picture_cloudinary,
        type: 'cloudinary',
      },
    }
  }

  rootStore.SET_PAGE_BANNER(pageBanner)

  return {
    firstTrip,
    campaigns,
  }
})
const firstTrip = data.value?.campaigns
const campaigns = data.value?.campaigns

useHead({
  title: () => `${t('siteTitle')}`,
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: () => t('homeDescription'),
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: () => `${localeURLs.BASE_URL}`,
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: () => `${t('siteTitle')} - ${t('siteTitleBrand')}`,
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: () => formatCloudinaryImagePath(firstTrip?.main_picture_cloudinary, {
        forceJPG: true,
        isSol: false,
      }),
    },
    {
      hid: 'og:description',
      name: 'og:description',
      content: () => t('homeDescription'),
    },
    {
      name: 'google-site-verification',
      content: 'vCKb9vRFS3-tspc-TOjttHLY7S0A9wNQbWEXBLFm08E',
    },
  ],
})

const campaignList = computed(() => {
  return [ ...(campaigns || []) ]
    .filter(Boolean)
    .filter(campaign => campaign?.sitemap === 2)
})

const hasBlog = computed(() => LOCALES_WITH_BLOG.includes(locale.value))
</script>

<style lang="scss">
.startpage-rolfs {
  .startpage-campaign-slide {
    height: 450px;

    @media (max-width: theme('screens.md')) {
      height: 350px;
    }
  }

  &.startpage-category-section {
    padding-top: 70px;
    margin-top: 0;
    position: relative;
    background: theme('colors.lightest-blue');

    @media (max-width: theme('screens.md')) {
      padding-top: 1rem;
    }
  }

  .startpage-ongoing-campaigns {
    font-family: theme('fontFamily.serif');
  }

  .trip {
    &-item {
      .trip {
        &-description {
          color: white;
          background: transparent;
        }

        &-image {
          height: 400px;
        }
      }

      &.startpage-category-item {
        height: 160px;
        min-height: 160px;
      }
    }

    &-description {
      text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.57);
      font-weight: 500;
    }
  }

  .startpage-category-slide {
    height: 270px !important;
  }

  &_info-grouptravel {
    button {
      $x-padding: 2.2rem;
      padding-left: $x-padding;
      padding-right: $x-padding;

      h4 {
        font-size: 1.3rem;
      }
    }

    &:hover {
      button {
        background: theme('colors.orange.DEFAULT') !important;
      }
    }
  }
}
</style>
